.ts-section {
	padding: 0 4%;
	position: relative;
	&__inner {
		margin:0 auto;
		padding: 40px 0;
		width: 100%;
		max-width: $section-width;
		position: relative;
		z-index: 2;
	}
	&__bg {
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		z-index: 1;
	}

	&--error404-menu {
		.ts-section__inner {
			padding-bottom:0;
		}
	}
	&--error404 {
		padding: 0 4% 0 4%;
		.ts-section__inner {
			padding:100px 7%;
			min-height: calc(100vh - 335px);
			background: rgba(#fff,0.9);
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
		}
		h1 {
			@include font-size(120);
			line-height: 1;
			font-weight: 700;
			margin-top: 0;
			margin-bottom: 30px;
			font-family: $header-font;
			color: $grey-blue;
		}
	}
}
