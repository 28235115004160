.main-footer {
	text-align:center;
	width:100%;
	padding:20px 0;
	font-size:12px;
	color:#FFF;
	text-shadow: 0px 0px 2px rgba(#333, 0.5);
	position:relative;
	z-index:30;
	&--mobile {
		color:rgba($text-dark, 0.4);
		text-shadow: none;
	}
}
