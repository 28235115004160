.image-stack {
	width: 100%;
	&__slide {
		width: 100%;
		margin-bottom: 10px;
		height:0;
		position: relative;
	}
	&__image {
		display: block;
		width: 100%;
		height: auto;
		position: absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
	}
}
