.main-header {
	padding:0 4%;
  background: $body-bg;
	color:#fff;
	height: $header-height;
	position: fixed;
	top:0;
	left:0;
	right:0;
	z-index: 200;
	width: 100%;
	display: flex;
	align-items: center;
	&__inner {
		width: 100%;
		margin:0 auto;
		max-width: $section-width;
	}

	&--mobile {
		z-index: 2000;
		border-bottom: $border-color 5px solid;
	}

	.flex {
		align-items: center;
	}

	.intro {
		text-align: right;

		&__name {
	    color: #FFF;
			@include font-size(45);
	    font-weight: 700;
	    letter-spacing: 2px;
			line-height: 1.3;
		}
		&__jobdesc {
	    color: #fff;
			@include font-size(20);
			margin-bottom: 4px;
			line-height: 1;
		}
		&__languages {
	    color: #fff;
	    font-size: 14px;
		}
	}

	@include respond-large-down {
		height: $header-height-mob;

		.intro {
			text-align: left;

			&__name {
				@include font-size(30);
			}
			&__jobdesc {
				@include font-size(16);
			}
			&__languages {
				display: none;
					@include font-size(12);
			}
		}

	}

	@include respond-vsmall-down {
		.intro {
			&__name {
				@include font-size(28);
			}
		}
	}



}
