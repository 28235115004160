.screen-reader-text,
.visually-hidden {
	position: absolute !important;
	clip: rect(1px, 1px, 1px, 1px);
	overflow: hidden;
	height: 1px;
	width: 1px;
	word-wrap: normal;
}
.visually-hidden.focusable:active,
.visually-hidden.focusable:focus {
	position: static !important;
	clip: auto;
	overflow: visible;
	height: auto;
	width: auto;
}
.invisible {
	visibility: hidden;
}

.notransition {
	-o-transition: none !important;
	-ms-transition: none !important;
	-moz-transition: none !important;
	-webkit-transition: none !important;
	transition: none !important;
}

.skiptocontent {
	display: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	text-align: center;
	padding: 20px;
	background: #f7f7f7;
	color: #000;
	border-bottom: 1px solid #ccc;
}
@media only screen and (min-width: 768px)	{
	.skiptocontent {
		display: block;
	}
}


.Accordion, .Accordion-item-wrap {
	border-bottom: 1px solid rgba(0,0,0,0.1);
	border-radius: 0;
	background: transparent;
	margin: 30px 0;
	overflow: hidden;

}

.wp-block-gpbg-faq {
	&:first-child {
		border-top: 1px solid rgba(0,0,0,0.1);
	}
}

.Accordion-item-wrap.active {
	/* border: 2px solid #0098b9; */
}
.Accordion-item-wrap.active .Accordion-trigger {
	// color:#fff !important;
	// background: $blue;
}

.Accordion.separated {
  border:none;
  border-radius: 0;
  box-shadow: none;
  background:transparent;
}

.Accordion-item-wrap { margin: 0; background: transparent;;}

.Accordion > * + * {
	/* border-top: 1px solid hsl(0, 0%, 82%); */
}

.Accordion > dt {
	border-top: 1px solid rgba(0,0,0,0.1);
}

.Accordion-trigger {
	background: transparent;
	border: 0;
	display: block;
	font-size: 1.25rem;
	line-height: 1.2;
	font-weight: 400;
	margin: 0;
	padding: 20px 50px 20px 20px;
	position: relative;
	text-align: left;
	width: 100%;
	transition: 0.3s ease-out;
	border-radius: 0;
	text-transform: none;
	color: $text-dark;
	font-family: $main-font;
	outline: none;
}

.Accordion dt:first-child .Accordion-trigger {
	border-radius: .3em .3em 0 0;
}

.Accordion-trigger:focus,
.Accordion-trigger:hover {
	background: rgba(0, 0, 0, 0.02);
	outline: none;
}

.Accordion-title {
  display: block; /* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/8295099/ */
  pointer-events: none;
}

.Accordion-icon {
	border: solid hsl(0, 0%, 62%);
	border-width: 0 2px 2px 0;
	height: .5rem;
	pointer-events: none;
	position: absolute;
	right: 1.5em;
	top: 50%;
	transform: translateY(-60%) rotate(45deg);
	width: .5rem;
	transition: border-color 0.3s ease-out, transform 0.3s ease-out;
}

.Accordion-trigger:focus .Accordion-icon {
	border-color: $text-dark;
}
.Accordion-trigger:hover .Accordion-icon {
	border-color: $text-dark;
}

.Accordion-trigger[aria-expanded="true"] .Accordion-icon {
	transform: translateY(-50%) rotate(-135deg);
}

.Accordion-panel {
	margin: 0;
	padding: 1em 1.5em;
}
.Accordion-panel a { color:#d2232a; }
.Accordion-panel a:hover { }

/* For Edge bug https://developer.microsoft.com/en-us/microsoft-edge/platform/issues/4806035/ */
.Accordion-panel[hidden] {
  display: none;
}
