// fonts
$main-font: 'Lato', serif;
$header-font: "Montserrat", sans-serif;

$grey : #dfdfdf;
$dark-grey : #9BACB2;
$mid-grey: #EDEDED;
$light-grey: #FAFAFA;
$avatar-grey: #c5c5c5;


$pink: #e62a76;
$grey-blue: #7fa1b5;

$grey-blue-grad: linear-gradient(180deg, rgba(250,250,250,1) 0%, rgba(140,220,251,1) 100%);

$body-bg: #1a1a1a;
$blue-bg: #8cdcfb;
$border-color: #5A5757;

$text-dark: #000000;
$text-light: #000000;

$complete: #50CAF5;
$error: #c80000;
$required: #F84610;

$section-width: 1100px;
$section-width-narrow: 1070px;

$header-height: 135px;
$header-height-mob: 90px;

$project-pink: #e62a76;
$project-orange: #e84d3a;
$project-gold: #b67b5f;
$project-yellow: #fdba2c;
$project-blue: #2ba9c9;
$project-dark-blue: #366796;
$project-red: #ff0202;
$project-green: #5ca057;
$project-grey: #23282d;
$project-brick: #b26067;
$project-sand: #F1BF98;
