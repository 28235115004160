*, *:before, *:after {
  box-sizing: border-box;
}

*:focus-visible,
*:focus {
	outline-offset: 6px;
	outline-width: 2px;
	outline-color: #fff;
	outline-style: none;
}
a:focus-visible { outline-style:solid; }
button:focus-visible { outline-style:solid; }

body{
	background: $body-bg;
	margin: 0 auto;
	min-width: 320px;
  padding-top: $header-height;
	position: relative;
	margin:0;
	height: 100%;
	font-family: $main-font;
	font-size:16px;
  line-height: 1.5;
  overscroll-behavior: none;
	@include respond-large-down {
    padding-top: $header-height-mob;
  }
}
a,button {
	text-decoration:none;
	color: #999;
  transition: color 0.3s ease-out, background-color 0.3s ease-out, border-color 0.3s ease-out
}

p { margin-top:5px; }
ol,
ul {
  padding-left: 30px;
  li {

  }
}

hr {
  border:none;
  border-bottom: 1px solid $border-color;
  margin: 20px 0;
}

h1 { font-weight: normal; @include font-size(35); font-family: $header-font; }
h2 { font-weight: normal; @include font-size(26); font-family: $header-font; }
h3 { font-weight: normal; }
h4 { font-weight: bold; text-transform: uppercase; }
h5 { font-weight: normal; }

h1,h2,h3,h4,h5 {
  font-weight: bold;
  &:first-child {
    margin-top: 0;
  }
  &:last-child {
    margin-bottom: 0;
  }
}

@include respond-small-down {
  h1 { @include font-size(32); }
  h2 { @include font-size(24); }
}

// a:not(.btn) {
//   color:$complete;
//   text-decoration: underline;
//   position: relative;
//   &:hover, &:active {
//     color: darken($complete, 20%);
//   }
// }

.no-scroll {
  overflow: hidden;
}

pre {
  padding:10px 10px 10px 26px;
  border-radius: 5px;
  border: 1px solid $border-color;
}

img { width: 100%; height: auto; }

figure {
  margin: 40px 0;
}

.hidden {
	display: none;
}
.clear { clear:both; }
.flex {
	display: flex;
	margin: 0 -15px;
}
.flex--wrap {
	flex-wrap: wrap;
}
.flex--align-v {
	align-items: center;
}
.flex .col { flex-grow: 1; }
.flex .col-2 { width:16.6666%; }
.flex .col-3 { width:25%; }
.flex .col-4 { width:33.3333%; }
.flex .col-5 { width:41.6666%; }
.flex .col-6 { width:50%; }
.flex .col-7 { width:58.3333%; }
.flex .col-8 { width:66.6666%; }
.flex .col-9 { width:75%; }
.flex .col-12 { width:100%; }
.flex .col-20 { width:20%; }
.flex .col, .flex .col-2, .flex .col-3, .flex .col-4, .flex .col-5, .flex .col-6, .flex .col-7, .flex .col-8, .flex .col-9, .flex .col-12, .col-20 {
	padding: 0 15px;
}

.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}

.iframe-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	margin-bottom: 10px;
  iframe {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  }
}

#stats {
  position: fixed;
  top: 0;
  right: 0;
  width: max(200px, 10vw, 10vh);
  height: max(100px, 6vh, 6vw);
  opacity: 0.8;
  user-select: none;
  z-index: 99999;
}

#wrap {
  border-top: $border-color 5px solid;
  position: relative;
  z-index: 500;
  overflow: hidden;
  background-color: $blue-bg;
}

#main-content {
  background-color: $mid-grey;
  transition: background-color 0.5s ease-out;
  min-height: calc(100vh - 135px);
	@include respond-large-down {
    min-height: calc(100vh - 90px);
  }
}
