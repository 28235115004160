.contact {
	padding: 50px 10%;
	min-height: 400px;
	background: #224254;
	color:#fff;
	text-align: center;

	.contact-form {
		margin:30px 0 50px 0;
		padding:20px;
		background: rgba(#fff,0.1);
		text-align: left;
		label {
			color:#fff;
		}

		.btn {
			color:#fff;
			border-color:#fff;
			&:hover, &:active, &:focus {
				color:$text-dark;
				border-color:#fff;
				background-color:#fff;
			}
		}

		.response-message {
			margin-top: 20px;
			padding:10px 20px;
			border-left: 6px solid #ffb900;
			&.status-sent {
				border-left: 6px solid #46b450;
			}
		}
	}

	small {
		margin-top: 30px;
		display: block;
		a {
			color:#fff;
			&:hover, &:focus, &:active {
				text-decoration: underline;
			}
		}
	}

	@include respond-small-down {
		padding: 50px 6%;
	}
}

.social-links {
	padding:0; margin:0 -10px;
	li {
		display: inline-block; list-style: none; margin:0 4px;
		a,button {
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			background: transparent;
			border: none;
			display: block;
			min-width: auto;
			padding:0;
			@include font-size(26);
			transition: color 0.3s ease-out, transform 0.3s ease-out;
			color:#fff;
			&:hover, &:focus-visible, &:active {
				transform: scale(1.4);
			}
		}
	}
}
