// /* Slider */
// .slick-slider
// {
//     position: relative;
//
//     display: block;
//     box-sizing: border-box;
//
//     -webkit-user-select: none;
//        -moz-user-select: none;
//         -ms-user-select: none;
//             user-select: none;
//
//     -webkit-touch-callout: none;
//     -khtml-user-select: none;
//     -ms-touch-action: pan-y;
//         touch-action: pan-y;
//     -webkit-tap-highlight-color: transparent;
// }
//
// .slick-list
// {
//     position: relative;
//
//     display: block;
//     overflow: hidden;
//
//     margin: 0;
//     padding: 0;
// }
// .slick-list:focus
// {
//     outline: none;
// }
// .slick-list.dragging
// {
//     cursor: pointer;
//     cursor: hand;
// }
//
// .slick-slider .slick-track,
// .slick-slider .slick-list
// {
//     -webkit-transform: translate3d(0, 0, 0);
//        -moz-transform: translate3d(0, 0, 0);
//         -ms-transform: translate3d(0, 0, 0);
//          -o-transform: translate3d(0, 0, 0);
//             transform: translate3d(0, 0, 0);
// }
//
// .slick-track
// {
//     position: relative;
//     top: 0;
//     left: 0;
//
//     display: block;
//     margin-left: auto;
//     margin-right: auto;
// }
// .slick-track:before,
// .slick-track:after
// {
//     display: table;
//
//     content: '';
// }
// .slick-track:after
// {
//     clear: both;
// }
// .slick-loading .slick-track
// {
//     visibility: hidden;
// }
//
// .slick-slide
// {
//     display: none;
//     float: left;
//
//     height: 100%;
//     min-height: 1px;
// }
// [dir='rtl'] .slick-slide
// {
//     float: right;
// }
// .slick-slide img
// {
//     display: block;
// }
// .slick-slide.slick-loading img
// {
//     display: none;
// }
// .slick-slide.dragging img
// {
//     pointer-events: none;
// }
// .slick-initialized .slick-slide
// {
//     display: block;
// }
// .slick-loading .slick-slide
// {
//     visibility: hidden;
// }
// .slick-vertical .slick-slide
// {
//     display: block;
//
//     height: auto;
//
//     border: 1px solid transparent;
// }
// .slick-arrow.slick-hidden {
//     display: none;
// }
//


.slick-dots
{
	position: absolute;
	bottom: 0 !important;

	display: block;

	width: 100%;
	padding: 0;

	list-style: none;

	text-align: center;

	li {
		position: relative;

		display: inline-block;

		width: 18px;
		height: 18px;
		margin: 0 0px;
		padding: 0;

		cursor: pointer;
	}

	> li:only-child {
		display: none;
	}
}
.slick-dots li button
{
	font-size: 0;
	line-height: 0;

	display: block;

	width: 18px;
	height: 18px;
	padding: 4px;

	cursor: pointer;

	color: transparent;
	border: 0;
	outline: none;
	background: transparent;
	min-width: auto;
}
.slick-dots li button:hover,
.slick-dots li button:focus,
.slick-dots li button:active
{
	outline: none;
}
.slick-dots li button:hover:before,
.slick-dots li button:focus:before,
.slick-dots li button:active:before
{
	background-color: rgba($text-dark,1);
}
.slick-dots li button:before
{
	content: '';
	width: 10px;
	height: 10px;
	border-radius: 50%;
	position:absolute;
	top:4px;
	left:4px;
	background-color: rgba($text-dark,0.5);
}
.slick-dots li.slick-active button:before
{
	background-color: rgba($text-dark,1);
}

.slick-arrow { position: absolute; top:50%; margin-top:0; z-index:8; }
.slick-arrow a { display: block; cursor: pointer;  border: solid #fff; border-width: 0 5px 5px 0; padding: 16px; }
.slick-arrow.slick-disabled { opacity:0; }
.slick-arrow.arrow-prev { left:7%; }
.slick-arrow.arrow-prev a { transform: rotate(135deg); -webkit-transform: rotate(135deg); }
.slick-arrow.arrow-next { right:7%; }
.slick-arrow.arrow-next a { transform: rotate(-45deg); -webkit-transform: rotate(-45deg); }

@media only screen and (max-width: 767px)  {
	.slick-arrow.arrow-prev { left:2%; }
	.slick-arrow.arrow-next { right:2%; }
	.tile-news .title { opacity:1 !important; }
	.slick-arrow a { padding: 10px; }
}

.slick-slider {
	padding-bottom: 50px;
}

.slick-slider .slick-track, .slick-slider .slick-list { height:100%; }
.fade-slider .slick-track, .fade-slider .slick-list { height:auto; }
