.project-tile {


	&__title {
		@include font-size(20);
		font-weight:bold;
		color: #fff;
		padding: 0px 0 1px;
		margin-bottom: 6px;
		font-family: $header-font;
	}

	&__client {
		margin-bottom:6px;
		font-family: $main-font;
		@include font-size(16);
		color: #fff;
	}

	&__date {
		font-family: $main-font;
		@include font-size(16);
	}

	&__tags {
		padding:8px 0;
		right:10px;
		margin-top:10px;
		justify-content: flex-end;
	}
}


.project-list {
	&__msg {
		padding: 50px 20px;
		background: #fff;
	}
	.loading {
		padding:40px 0;
		margin:0;
	}

	.load-more {
		display: block;
		width: 100%;
		border:none;
		-webkit-appearance: none;
		appearance: none;
		background: #fff;
		padding:20px 10px;
		@include font-size(16);
		color: $text-dark;
		&:hover, &:focus, &:active {
			background: $pink;
			color: #fff;
		}
		span {
			display: inline-block;
			vertical-align: middle;
		}
		.loading {
			display: none;
			padding:0;
			vertical-align: middle;
			padding-left:10px;
			.spinner {
				height:20px;
			}
		}
		&.is-loading {
			.loading {
				display: inline-block;
			}
		}
	}

	.project-tile {
		position: relative;
	  padding: 15px 10px 15px 10px;
	  border-bottom: #CCC 1px solid;
	  background: #FFF;

		&__panel {
			overflow: hidden;
			padding-bottom: 60.301%;
			display: block;
			position: relative;
			background: $mid-grey;
			&:hover, &:focus-visible, &:active {
				.project-tile__bg {
					transform: translate3d(0, -15%, 0);
				}
				.project-tile__meta {
					transform: translate3d(0, 0, 0);
				}
			}
		}

		&__meta {
			position: absolute;
	    bottom: 0;
	    width: 100%;
	    background: #F1733C;
	    padding: 20px;
	    color: #fff;
	    transform: translate3d(0, 100%, 0);
	    transition: transform 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
		}

		&__bg {
			width: 100%;
	    height: 100%;
	    position: absolute;
	    top: 0;
	    left: 0;
	    display: block;
	    transition: transform 0.3s cubic-bezier(0.46, 0.03, 0.52, 0.96);
			background-size: cover !important;
		}
	}

}

.ts-section--col-projects {
	padding:0;
	background: $light-grey;
	.ts-section__inner {
		padding: 0
	}
}

.project-tags {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-end;
	margin:0;
	padding:0;
	.project-tag {
		margin:0;
		margin-left:5px;
		margin-bottom:6px;
		list-style: none;
		a {
			display: block;
			padding:6px 10px;
			background:#aeadb3;
			color:#fff;
			@include font-size(18);
			font-weight: 700;
			&:hover, &:focus, &:active {
				background:#e62a76;
			}
		}
	}
}

.project-slider {
	padding:40px 4%;

	border-bottom: 20px solid #fff;
	&:first-child {
		border-top: 20px solid #fff;
	}

	.slick-dots li button:before {
		color: #000;
		background-color: #000;
	}

	.view-all {
		color:$text-dark;
		font-weight: bold;
		float:right;
		margin-top: -20px;
		display: block;
		position: relative;
		z-index: 20;
		&:after {
			content:'\e801';
			font-family: fontello;
			display: inline-block;
			margin-left: 10px;
			transition:transform 0.3s ease-out;
			line-height: 1;
			position: relative;
			top:0;
		}
		&:hover, &:focus, &:active {
			&:after {
				transform: translate(6px,0);
			}
		}
	}

	/*
	// background: #fff;
	&--featured {
		background: rgba(#F1BF98, 0.9); // sand
	}
	&--sites {
		// background: rgba(#CC2936, 0.4); // red
		background: rgba(#b26067, 0.7); // brick red
		// background: #d3c5d7; // light aubergine
		// background: rgba(#8E7C93, 0.4); // aubergine
	}
	&--games {
		//background: #669BBC; // light blue
		background: rgba(#FFEECF, 0.9); // light sand
		// background: #6C464F; // Brown
	}
	&--mobile {
		background: rgba(#08415C, 0.4); // dark blue
		// background: #6A0136; // burgandy
		// background: rgba(#b78ba1, 0.4); // light burgandy
		// background: rgba(#69626D, 0.4); // grey burgandy
	}
	&--design {
		// background: #FF47DA; // hot pink
		background: rgba(#fd7509, 0.6); // gold
	}
	&--ai {
		background: #fff;
	*/


	&--featured {
		background: $project-sand;
	}
	&--sites {
		background: $project-pink;
	}
	&--games {
		background: $project-orange; // $project-brick;
	}
	&--mobile {
		background: $project-blue;
	}
	&--design {
		background: $project-gold;
	}
	&--ai {
		background: $project-orange;
	}

	// white text
	&--games,
	&--mobile,
	&--sites,
	&--design {
		color: #fff;
		.slick-dots li button:before {
			color: #fff;
			background-color: #fff;
		}
		.slick-dots li.slick-active button:before {
			color: #fff;
			background-color: #fff;
		}
		.view-all {
			color:#fff;
		}
	}

	.slick-slide {
		height: auto;
		a {
			display: block;
		}
		> div {
			height: 100%;
			> a {
				height: 100%;
			}
		}
	}
	.slick-slider {
		margin:0 -15px;
	}
	.slick-list {
		overflow: visible;
	}
	.slick-track {
		display: flex;
	}
	.slick-dots {
		text-align: left;
		padding:0 15px;
	}
	.project-tile {
		border-bottom: none;
		padding:15px;
		&__panel {
			display: block;
			height:0;
			width: 100%;
			border:none;
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			text-align: left;
			padding-bottom: calc(60.301% + 100px);
			position: relative;
			box-shadow: 0 0 8px 2px rgba(#000, 0.05);
		}
		&__bg {
			position: absolute;
			top:0;
			left:0;
			right:0;
			height:0;
			padding-bottom: 60.301%;
			background-position: center !important;
			background-size: cover !important;
		}
		&__meta {
			position: absolute;
			bottom:0;
			left:0;
			right:0;
			min-height:100px;
	    background: $project-grey !important;
	    padding: 10px;
	    color: #fff;
		}

		&__title {
			@include font-size(18);
			margin-bottom:2px;
		}

		&__client {
			margin-bottom:2px;
			@include font-size(16);
		}

		&__date {
			@include font-size(16);
		}
	}

	.view-more {
		width:100%;
		height:100%;
		padding:15px;
		&:hover, &:focus, &:active {
			.view-more__inner {
				background: #fff;
				color:$pink;
				span {
					&:after {
						transform: translate(20px,0);
					}
				}
			}
		}
		&__inner {
			width:100%;
			height:100%;
			display: flex;
			align-items: center;
			justify-content: center;
			background: $project-grey;
			color: #fff;
			transition:background-color 0.3s ease-out, color 0.3s ease-out;
			box-shadow: 0 0 8px 2px rgba(#000, 0.05);
			span {
				display: block;
				@include font-size(30);
				position: relative;
				&:after {
					content:'\e801';
					font-family: fontello;
					display: inline-block;
					margin-left: 10px;
					transition:transform 0.3s ease-out;
					line-height: 1;
					position: relative;
					top:3px;
				}
			}
		}
	}
}


/* Project Single */
.ts-section--single-project {
	.flex {
		.col-main {
			padding:0 15px;
			width: 65%;
		}
		.col-side {
			padding:0 15px 0 0;
			width: 35%;
		}
	}

	.project-detail {
		background: #fff;
		padding: 35px 20px 50px 20px;
		min-height: 400px;
		&__thumbnail {
			max-width: 250px;
			margin:0 auto;
			.image-wrap {
				height:0;
				width: 100%;
				position: relative;
				padding-bottom:108.8967971530249%;
				img {
					display: block;
					position: absolute;
					top:0;
					bottom:0;
					left:0;
					right:0;
					width: 100%;
					height: 100%;
				}
			}
		}
		&__name {
			@include font-size(25);
	    font-family: $header-font;
	    font-weight: 600;
	    margin-bottom: 4px;
		}

		&__nav {
			display: flex;
			justify-content: space-between;
		}

		&__client {
			margin-bottom: 6px;
			@include font-size(16);
		}

		&__date {
			margin-bottom: 30px;
			@include font-size(14);
		}

		&__link {
			//text-align: right;
			margin-top: 30px;
			@include font-size(14);
		}

		hr {
			margin: 30px 0;
			border-color: $border-color;
		}

		&__description {
			@include font-size(18);
		}

	}

	@include respond-medium-down {
		.flex {
			flex-wrap: wrap;
			.col-main {
				padding:0 15px;
				width: 100%;
				order: 2;
			}
			.col-side {
				padding:0 15px;
				width: 100%;
				order: 1;
				margin-bottom: 50px;
			}
		}
	}
}
