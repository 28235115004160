.table {
	display: table;
}

.table-cell {
	display: table-cell;
}

.valign-m {
	vertical-align: middle;
}

.form-group {
	//margin:0 auto 30px auto;
	margin: 0 0 30px 0;
	padding:25px 15px;
	// max-width: 600px;
	// -webkit-box-shadow: 0px 2px 8px 2px rgba(0,0,0,0.05);
	// box-shadow: 0px 2px 8px 2px rgba(0,0,0,0.05);
	border-radius: 4px;
	border: 1px solid rgba(0,0,0,0.05);
	&:last-of-type {
		margin-bottom: 0;
	}

	h3 {
		&:first-child {
			margin-top: 0;
			margin-bottom: 30px;
		}
	}

	.form-row {
		// &:last-child {
		// 	margin-bottom: 0;
		// }
	}
}

input[type=text],
input[type=number],
input[type=tel],
input[type=password],
input[type=email],
input[type=date],
input[type=datetime-local],
input[type=url],
.form-html-view,
.read-only-input,
textarea, select {
	width: 100%;
	margin: 0;
	display: inline-block;
	box-shadow: none; //inset 0 1px 3px #ddd;
	border-radius: 4px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding: 0 10px;
	@include font-size(15);
	line-height: 1;
	height:40px;
	-webkit-appearance: none;
	appearance: none;
	font-family: 'Source Sans Pro', sans-serif;
	transition: border-color 0.4s ease-out;
	&.error {
		border-left: 5px solid #ff185a !important;
	}
	// &:focus {
	// 	outline: none;
	// 	border-color: $dark-blue;
	// }
}
textarea {
	height: auto;
	padding:10px 10px
}
select {
	background:#fff url(../images/svg/select-arrow.svg) no-repeat;
	background-size: 8px 7px;
	background-position: calc(100% - 20px) 50%;
	padding-right: 40px;
}

.form-row {
	margin-bottom: 20px;
	input[type=text],
	input[type=number],
	input[type=tel],
	input[type=password],
	input[type=email],
	input[type=date],
	input[type=datetime-local],
	input[type=url],
	.form-html-view,
	.read-only-input,
	textarea, select {
		border: 1px solid #E8E9EC;
		// max-width: 800px;
		// &:focus {
		// 	border: 1px solid $blue;
		// }
	}
	.flex {
		margin:0 -15px !important;
	}
	textarea {
		resize: vertical;
		height: auto;
		padding: 15px 10px;
		height: auto;
	}
	input[type=date] {
		// font-family: inherit;
		text-transform: uppercase;
	}
	input[type="checkbox"] {
		display: inline-block;
		vertical-align: middle;
	}
	.lbl-inline {
		display: inline-block;
		vertical-align: baseline;
		margin-left: 10px;
		width: auto;
	}
	.read-only-input {
		padding-top: 11px;
		padding-bottom: 11px;
		color: rgba($text-dark, 0.6);
		background: $light-grey;
	}
	.form-html-view {
		background-color: #fff;
		max-height: 300px;
		overflow: scroll;
		box-shadow: none;
		box-shadow: 0 2px 4px 0 rgba(0,0,0,0.09);
		min-height: 150px;
		blockquote {
			padding: 5px 30px;
			border-left: 4px #00142a solid;
		}
	}
	.editor-wrap {
		&.error {
			border-left: 5px solid #ff185a !important;
		}
	}
	label {
		width: 100%;
		display: block;
		margin-bottom: 5px;
		@include font-size(14);
		color: $text-light;
	}
	.btn-wrap {
		margin-top: 50px;
	}
	.date-to-from {
		width:100%;
		display: flex;
		input {
			width:50%;
		}
	}
	.error-msg {
		@include font-size(14);
		margin-top: 8px !important;
		display: block;
	}
	label.error {
		span {
			color: $error;
		}
	}
	.error-msg {
    margin: 4px 0;
	}
	&--medium,
	&--mid {
		input,select,textarea {
			max-width: 400px;
		}
	}
	&--small {
		input,select,textarea {
			max-width: 250px;
		}
	}
	&--no-margin {
		margin-bottom: 0;
	}
	@include respond-small-down {
		.flex {
			flex-direction:column;
			.col-6 {
				width:100%;
				margin-bottom: 20px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
.input-row {
	margin-top: 40px;
	display: flex;
	max-width: 600px;
	.btn {
		width: 180px;
	}
	input {
		width: calc(100% - 200px);
		margin-right: 20px
	}
}
.msg-error {
	@include font-size(12);
	padding:4px;
	color:$error;
}

// .required {
// 	color: $required;
// 	&:after {
// 		content: '*';
// 		display: inline-block;
// 		margin-left: 10px;
// 		color: $required;
// 	}
// }

.form-row .midwidth {
	max-width: 160px;
}
