
.sub-nav {
	color:#FFF;
	padding-top:20px;
  ul {
		padding:0;
		margin:0;
    display: flex;
    li {
    	font-size:18px;
    	margin-right:1px;
			list-style: none;
      &.hidden { display:none; }
      position: relative;
      > a, > div {
        display: block;
    	  padding:5px 10px;
        color:#FFF;
      	background:rgba(51,51,51,.4);
        transition: color 0.3s ease-out, background-color 0.3s ease-out;
        &:hover, &:focus, &:active {
      	   background:rgba(51,51,51,.8);
        }

        .btn-close {
          position:absolute;
          top:0;
          right:0;
          padding:5px 0 5px 5px;
          text-align: center;
          color: #fff;
        	background: transparent;
          transition: color 0.3s ease-out;
        }
      }
      &.active {
        > a, > div {
          background:#FFF;
          color:#666;
          .btn-close {
            color:#666;
            &:hover, &:focus, &:active {
              color: #000;
            }
          }
        }
      }
      &.has-close {
        > a, > div {
          padding-right: 30px;
        }
      }
    }
  }
}


.ts-section--home-projects {
	.cat-header {
		margin-bottom: 30px;
		text-transform: capitalize;
	}
	.tag-header {
		padding:10px 10px 0 10px;
		background: #fff;
		&__inner {
			padding: 15px 20px;
			@include font-size(22);
			line-height: 1;
			font-family: $header-font;
			background: #aeadb3;
			span {
				display: block;
				text-align: center;
				color: #fff;
			};
		}
	}
	.breadcrumbs {
		margin-bottom: 5px;
	}
	.loading-wrap {
		padding:10px;
		background: #fff;
	}
}

.ts-section--home {

	.flex {
		margin:0 -5px;
	}
	.col-about {
		order:2;
		padding:0 5px;
		width: 365px;
	}
	.col-projects {
		order:1;
		padding:0 5px;
		width: calc(100% - 365px);

		.project-list {
			background: #fff;
		}
	}

	.tag-header {
		padding:10px 10px 0 10px;
		background: #fff;
		&__inner {
			padding: 15px 20px;
			@include font-size(22);
			line-height: 1;
			font-family: $header-font;
			background: #aeadb3;
			span {
				display: block;
				text-align: center;
				color: #fff;
			};
		}
	}

	.loading-wrap {
		padding:10px;
		background: #fff;
	}

	.home-panel {
		margin-bottom: 10px;
		color:#fff;
		&__inner {
			padding: 35px 20px;

			h3 {
				&:first-of-type {
					margin-top: 0;
				}
			}
		}
		&--about {
			.home-panel__inner {
				background: #0e5679; //#333333;
			}
		}
		&--skills {
			.home-panel__inner {
				background: #5e9ec3; //#ff451e;
				// color: $text-dark;
				h2 {
					text-align: center;
				}

				h3 {
					border-bottom: 1px solid rgba(#fff, 0.4);
					padding-bottom: 10px;
					margin-bottom: 10px;
					margin-top: 40px;
				}
			}
		}
		&--offer {
			.home-panel__inner {
				background: #7fa1b5;
				text-align: center;
			}
		}
		&--git {
			.home-panel__inner {
				background: #224254;
				text-align: center;
			}
			ul {
				margin:0;
				padding:0;
				li {
					margin:0;
					padding: 10px 10px;
					list-style:none;
					display: inline-block;
					a {
						color:#fff;
						@include font-size(30);
						transition: color 0.3s ease-out, transform 0.3s ease-out;
						display: block;
						&:hover, &:active {
							transform: scale(1.3);
							//color: $pink;
						}
					}
				}
			}
		}
		&--unlock {
			.home-panel__inner {
				background: #1d2f38;
				text-align: center;
			}
		}
	}

	.icon-list {
		padding:0;
		margin: 30px auto 0 auto;
		max-width: 300px;
		display: flex;
		flex-wrap: wrap;
		li {
			width: 50%;
			padding:0;
			margin:0;
			list-style: none;
			margin-bottom: 30px;
			a {
				display: block;
				color:#fff;
				cursor: pointer;
				.icon-list-footer {
					color:#fff;
					transition: color 0.3s ease-out;
				}
				.icon {
					display: block;
					width: 60px;
					height: 55px;
					margin: 0 auto 20px auto;
					transition: color 0.3s ease-out, transform 0.3s ease-out;
					&.icon-sites {
						background: url(../images/svg/icon-websites.svg) no-repeat center;
						background-size:contain;
					}
					&.icon-games {
						background: url(../images/svg/icon-games.svg) no-repeat center;
						background-size:contain;
					}
					&.icon-apps {
						background: url(../images/svg/icon-app.svg) no-repeat center;
						background-size:contain;
					}
					&.icon-design {
						background: url(../images/svg/icon-design.svg) no-repeat center;
						background-size:contain;
						width: 45px;
					}
				}

				&:hover, &:focus, &:active {
					.icon {
						transform: scale(1.2);
					}
					.icon-list-footer {
						// color: $pink;
					}
				}
			}
		}
	}

	@include respond-large-down {
		.col-about {
			width: 100%;
		}
		.col-projects {
			width: 100%;
		}

		.home-panel {
			display: flex;
			justify-content: flex-end;
			margin-bottom: 30px;
			&__inner {
				width:calc(100% - 200px);
			}
			&:nth-child(2n+1) {
				justify-content: flex-start;
			}
		}
	}

	@include respond-small-down {
		.home-panel {
			&__inner {
				width:calc(100% - 60px);
			}
			&--about {
				.home-panel__inner {
					width:calc(100% - 40px);
				}
			}
			&--skills {
				.home-panel__inner {
					h3 {
						margin-top: 20px;
						&:first-of-type {
							margin-top: 0;
						}
					}
				}
			}
		}
	}
}


#wrap {
	&.is-mobile {
	  border-top: none;
	}
}
#wrap.home {
	&.is-mobile {
		#main-content {
			background: $light-grey; //$grey-blue-grad;
			background: $grey-blue-grad;
		}
	}
	&--404 {
		#main-content {
			background: $blue-bg !important;
		}
	}
}

@include respond-large-up {
	#wrap.home {
		background: transparent !important;
		#main-content {
			background: transparent;
		}
	}
	.wrap-bg {
		position: absolute;
    z-index: 300;
    background-color: $blue-bg;
    top: 135px;
    left: 0;
    bottom: 0;
    right: 0;

	  &--night {
			background-color: #2a2e3a;
	  }
	  &--sunrise,
	  &--twilight,
	  &--sunset {
			background-color: #ff7a81;
	  }
	}
	.parallax-bg {
		position: fixed;
    z-index: 400;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
		#animated-bg {
	    display: block;
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
		}

	}
}
