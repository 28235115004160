// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@import 'variables';
@import 'accessibility';
@import "mixins";
@import 'animations';

@import "general";
@import "form";
@import "ui";
@import "slick";
@import "imagestack";
@import "section";

@import "header";
@import "footer";

@import "home";
@import "project";
@import "contact";

@import "slide-nav";
@import "mobile-bg";
