.btn-wrap {
	padding-bottom: 10px;
	margin:0 -5px;
	&--centered {
		text-align: center;
	}
	&--right {
		text-align: right;
	}
	.btn, .button {
		margin:0 5px;
	}
}

.btn {
	display: inline-block;
	padding: 10px 15px;
	color: #fff;
	background: transparent;
	border: 1px solid $text-dark;
	border-radius: 0;
	cursor: pointer;
	transition: 0.3s ease-out;
	font-weight: bold;
	@include font-size(18);
	line-height: 1;
	text-align: center;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	position: relative;
	&:hover {
		background-color: transparent;
		color: $text-dark;
		border-color: $text-dark;
	}
	&:focus-visible {
		outline-style: none;
		&:after {
			content:"";
			position: absolute;
			left:-6px;
			right:-6px;
			top:-6px;
			bottom:-6px;
			-webkit-box-shadow: 0 0 0.0625rem 0.125rem #fff;
			box-shadow: 0 0 0.0625rem 0.125rem #fff;
			border-radius: 0.25rem;
			z-index: 90
		}
	}
	&.is-spinning {
		position: relative;
		padding-left:40px;
		&:before {
			content:"\e808";
			font-family: fontello;
			position: absolute;
			top:50%;
			font-size: 14px;
			line-height: 1;
			margin-top: -7px;
			left:10px;
			-webkit-animation: spin 2s infinite linear;
		}
	}
}

.btn-view {
	position: relative;
	padding-left: 20px;
	padding-right: 45px;
	&:after {
		position: absolute;
		top:50%;
		margin-top: -8px;
		right:15px;
		line-height: 1;
		content: '\e801';
		font-family: fontello;
		transition: transform 0.3s ease-out;
	}
	&:hover, &:focus, &:active {
		&:after {
			transform: translateX(6px);
		}
	}
}

/*
.btn {
	display: inline-block;
	padding: 10px 25px;
	color: $dark-blue;
	background: transparent;
	border: 1px solid $dark-blue;
	border-radius: 5px;
	cursor: pointer;
	transition: 0.3s ease-out;
	font-weight: 300;
	@include font-size(12);
	line-height: 1;
	text-align: center;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	position: relative;
	&.active {
		color: #fff;
		background: $dark-grey;
		border: 1px solid $dark-grey;
	}
	&:active, &:hover {
		color: #fff;
		background: lighten($dark-blue, 20%);
		border: 1px solid $dark-blue;
	}
	&:focus-visible {
		outline-style: none;
		&:after {
			content:"";
			position: absolute;
			left:-6px;
			right:-6px;
			top:-6px;
			bottom:-6px;
			-webkit-box-shadow: 0 0 0.0625rem 0.125rem $text-dark;
			box-shadow: 0 0 0.0625rem 0.125rem $text-dark;
			border-radius: 0.25rem;
			z-index: 90
		}
	}
	&.is-spinning {
		position: relative;
		padding-left:40px;
		&:before {
			content:"\e839";
			font-family: fontello;
			position: absolute;
			top:50%;
			font-size: 14px;
			line-height: 1;
			margin-top: -7px;
			left:10px;
			-webkit-animation: spin 2s infinite linear;
		}
	}
	@include respond-vsmall-down {
		width: 100%;
	}
	&:disabled, &.disabled {
		opacity: 0.5;
	}
}
*/

/* burger button */
.lines-button {
	width: 48px;
	height: 48px;
	margin: 0;
	margin-right: 0;
	padding: 10px;
	background: transparent;
	text-shadow: none !important;
	border: none !important;
	box-shadow: none !important;
	transition: none;
	cursor: pointer;
	user-select: none;

	span {
		display: block;
		margin-bottom: 3px;
	}
	&.nav-close .lines {
		background: transparent;
		&:before, &:after {
			-ms-transform-origin: 50% 50%;
			-moz-transform-origin: 50% 50%;
			-webkit-transform-origin: 50% 50%;
			transform-origin: 50% 50%;
			top: 0;
      width: 25px;
      left: 0;
		}
		&:before {
			-ms-transform: rotate3d(0, 0, 1, 45deg);
			-webkit-transform: rotate3d(0, 0, 1, 45deg);
			-moz-transform: rotate3d(0, 0, 1, 45deg);
			transform: rotate3d(0, 0, 1, 45deg);
		}
		&:after {
			-ms-transform: rotate3d(0, 0, 1, -45deg);
			-webkit-transform: rotate3d(0, 0, 1, -45deg);
			-moz-transform: rotate3d(0, 0, 1, -45deg);
			transform: rotate3d(0, 0, 1, -45deg);
		}
	}

	.lines {
		display: inline-block;
		width: 22px;
		height: 3px;
		background: #fff;
		transition: 0.3s;
		position: relative;
		&:before, &:after {
			display: inline-block;
			width: 22px;
			height: 3px;
			background: #fff;
			transition: 0.3s;
			position: absolute;
			left: 0;
			content: '';
		}
		&:before {
			top: 8px;
		}
		&:after {
			top: -8px;
		}
	}
  &.active {
    // border-radius:50%;
    // border-top-right-radius: 0;
    // border-bottom-right-radius: 0;
    // .lines {
  	// 	&:before, &:after {
  	// 		background: #fff;
    //   }
    // }
  }
	// @include respond-medium-down {
	// 	display: block;
	// }
}

.loading {
	margin:40px 0;
}
.spinner {
  margin: 0 auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;

	> div {
	  background-color: $mid-grey;
	  height: 100%;
	  width: 6px;
	  display: inline-block;
		margin:1px;
	  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
	  animation: sk-stretchdelay 1.2s infinite ease-in-out;
	}

	.rect2 {
	  -webkit-animation-delay: -1.1s;
	  animation-delay: -1.1s;
	}

	.rect3 {
	  -webkit-animation-delay: -1.0s;
	  animation-delay: -1.0s;
	}

	.rect4 {
	  -webkit-animation-delay: -0.9s;
	  animation-delay: -0.9s;
	}

	.rect5 {
	  -webkit-animation-delay: -0.8s;
	  animation-delay: -0.8s;
	}
}

figure {
	max-width: 700px;
	margin: 40px 0;
	img {
		display: block;
		width: 100%;
		height: auto;
		border-radius: 5px;
		-webkit-box-shadow: 0px 2px 8px 2px rgba(0,0,0,0.05);
		box-shadow: 0px 2px 8px 2px rgba(0,0,0,0.05);
	}
	figcaption {
		margin: 10px;
	}
}

/* breadcrumbs */
.breadcrumbs {
	margin-bottom: 20px;
	.breadcrumb {
		display: inline-block;
		vertical-align: middle;
		&:after {
			content: '/';
			margin:0 10px 0 8px;
			text-decoration: none;
			display: inline-block;
			vertical-align: middle;
			color: $text-dark;
		}
		&:last-child:after {
			display: none;
		}
	}
}

table {
	width: 100%;
	margin:0 0 1rem 0;
	border-collapse: collapse;
	tr {
		th {
			text-align: left;
			//text-transform: uppercase;
			color: $text-light;
			padding:10px;
			font-weight: 300;
			border-bottom: 1px solid $border-color;
		}
		td {
			//text-align: left;
			padding:10px;
			vertical-align: top;
			border-bottom: 1px solid $border-color;
		}

		&:last-child {
			td  {
				border-bottom: 0;
			}
		}
	}

	&.has-border {
		tr {
			th {
				border-bottom: 1px solid #ececec;
			}
			td {
				border-bottom: 1px solid #ececec;
			}
		}
	}

	tfoot {
		td {
			background: $light-grey;
		}
	}
}

.table {
	display: table;
	width: 100%;
  margin-bottom: 1rem;
	.tr {
		display: table-row;
		.th {
			display: table-cell;
			text-align: left;
			text-transform: uppercase;
			color: $text-dark;
			padding:10px;
		}
		.td {
			display: table-cell;
			//text-align: left;
			padding:10px;
			vertical-align: top;
		}
	}

	&.has-border {
		.tr {
			.th {
				border-bottom: 1px solid #ececec;
			}
			.td {
				border-bottom: 1px solid #ececec;
			}
		}
	}
}
.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

// span.pill {
// 	display: inline-block;
// 	padding:6px 18px;
// 	min-height: 24px;
// 	background:rgba(#F7941E, 0.4);
// 	border-radius: 12px;
// 	font-size: 12px;
// 	line-height: 1;
// 	text-transform: uppercase;
// 	&.complete,
// 	&.paid {
// 		background:rgba($complete, 0.4);
// 	}
// 	&.escalated,
// 	&.negative,
// 	&.overdue {
// 		background:rgba($error, 0.4);
// 	}
// 	&.pending,
// 	&.draft {
// 		background:rgba($pending, 0.4);
// 	}
// 	&.success {
// 		background:rgba($success, 0.4);
// 	}
// 	&.closed,
// 	&.void {
// 		background:rgba($grey, 0.4);
// 	}
// }


.error-msg {
	margin:40px 0;
	// color: $error;
	&:before {
		content: '\e80e';
		font-family: fontello;
		display: inline-block;
		vertical-align: middle;
		margin-right: 6px;
	}
}

.plusminus {
	position:absolute;
	width: 15px;
	height: 15px;
	top:18px;
	right:0;
	&:before,
	&:after { content: ""; position: absolute; background-color: #fff; transition: transform 0.25s ease-out; }
	&:before{ top: 0; left: 50%; width: 2px; height: 100%; margin-left: -1px; }
	&:after{ top: 50%; left: 0; width: 100%; height: 2px; margin-top: -1px; }
}
.expanded {
	.plusminus {
		&:before { transform: rotate(90deg); }
		&:after{ transform: rotate(180deg); }
	}
}

.expander {
	border: 1px solid $border-color;
	margin-bottom: 5px;
	&__header {
		padding:10px;
		background: $light-grey;
		position:relative;
		padding-right: 40px;
		cursor: pointer;
	}
	&__handle {
		position:absolute;
		top:0;
		right:0;
		color: $dark-grey;
		height:100%;
		width: 40px;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		transition: transform 0.3s ease-out
	}
	&__content {
		.content {
			padding:20px;
		}
	}
	&.expanded {
		.expander__handle {
			transform: rotateX(180deg);
		}
	}
}

.grecaptcha-badge {
	display: none !important;
}
