.mobile-bg {
	.sky {
		background: url(../images/mobile-bg/sky.png) repeat-x center;
		background-size: 100% 100%;
		position: absolute;
		top:0;
		left:0;
		right:0;
		height:60%;
	}
	.cliff {
		background: #d0d2d4;
		position: absolute;
		top:calc(55% + 490px);
		left:0;
		right:0;
		height:40%;
	}
	.sun {
		background: url(../images/mobile-bg/sun.png) no-repeat center;
		background-size: contain;
		width: 240px;
		height: 240px;
		position: absolute;
		top:50px;
		right:-50px;
	}
	.moon {
		background: url(../images/mobile-bg/moon.png) no-repeat center;
	}
	.volcano {
		background: url(../images/mobile-bg/volcano.png) no-repeat center bottom;
		background-size: contain;
		position: absolute;
		top:calc(55% - 150px);
		left:-100px;
		width: 389px;
		height: 174px;
	}
	.grass-1 {
		background: url(../images/mobile-bg/grass-1.png) repeat-x center;
		background-size: 100% 100%;
		position: absolute;
		top:55%;
		width:100%;
		height: 86px;
	}
	.grass-2 {
		background: url(../images/mobile-bg/grass-2.png) repeat-x center;
		background-size: 100% 100%;
		position: absolute;
		top:calc(55% + 65px);
		width:100%;
		height: 110px;
	}
	.grass-3 {
		background: url(../images/mobile-bg/grass-3.png) repeat-x center;
		background-size: 100% 100%;
		position: absolute;
		top:calc(55% + 140px);
		width:100%;
		height: 198px;
	}
	.grass-4 {
		background: url(../images/mobile-bg/grass-4.png) repeat-x center;
		background-size: 100% 100%;
		position: absolute;
		top:calc(55% + 290px);
		width:100%;
		height: 198px;
	}
	.dude {
		background: url(../images/mobile-bg/dude.png) no-repeat center;
		background-size: contain;
		width:112px;
		height: 383px;
		position: absolute;
		top:calc(55% + 220px);
		right:5%;
	}
	.grass-5 {
		background: url(../images/mobile-bg/grass-5.png) repeat-x center;
		background-size: 100% 100%;
		position: absolute;
		top:calc(55% + 450px);
		width:100%;
		height: 160px;
	}
	.platform {
		background: url(../images/mobile-bg/platform.png) no-repeat center;
		background-size: contain;
		position: absolute;
		top:calc(55% + 680px);
		left:-100px;
		width: 276px;
		height: 166px;
	}
	.clouds {
		.cloud {
			background: url(../images/mobile-bg/cloud.png) no-repeat center;
			background-size: contain;
			position: absolute;
			top:36%;
			left:12%;
			width: 148px;
			height: 74px;
			&:nth-child(2) {
				top:10%;
				left:60%;
				transform:scale(0.7);
			}
			&:nth-child(3) {
				top:25%;
				left:80%;
				transform:scale(0.8);
			}
			&:nth-child(4) {
				top:6%;
				left:-40px;
				transform:scale(0.6);
			}
		}
	}

	@include respond-small-down {

		.dude {
			right:-40px;
		}
	}

}

.home.is-mobile {
	.ts-section--home .home-panel .home-panel__inner {
		box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.1);
	}
}
