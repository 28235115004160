.slide-nav {
  position: fixed;
  top: 0;
  left: 0;
	right:0;
	bottom:0;
  background: $body-bg;
  color: #fff;
  transform: translateY(-100%);
  transition: transform 0.5s cubic-bezier(0.65, 0.05, 0.36, 1);
	z-index: 1000;
	padding:140px 0;
	overflow: auto;
	&__inner {
	}

	ul {
		padding: 0;
		margin:0;
		width: 100%;
		li {
			padding: 0;
			margin:0;
			list-style: none;
			&.active {
				a, button {
					background-color: $pink !important;
				}
			}
			a, button {
				display: block;
			  color: #fff;
				width: 100%;
				min-width: none;
				padding:15px 7%;
				text-align: center;
				@include font-size(22);
				-moz-appearance: none;
				-webkit-appearance: none;
				appearance: none;
				border:0;
				&:hover, &:focus, &:active {
					background-color: #717171;
				}
			}
			.submenu {
		  	background: #333;
				li {
					a, button {
						@include font-size(18);
						&:hover, &:focus, &:active {
							background-color: #717171;
						}
					}
				}
			}
		}
	}
}

.slide-nav.menu-out {
  transform: translateY(0);
}

.submenu {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.4s ease;
}

.submenu.open {
  max-height:285px; //height of subnav
}

.submenu-toggle {
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
	position:relative;

	.icon-down-open {
		font-family: fontello;
		content:'\e803';
		position: absolute;
		right:4%;
		top:16px;
		transition: transform 0.3s ease-out;
	}

	&.submenu-out {
		background: #484848;
		.icon-down-open {
			transform: rotateX(180deg);
		}
	}
}


.submenu-toggle:focus {
  outline: none;
}
