@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?56185592');
  src: url('../font/fontello.eot?56185592#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?56185592') format('woff2'),
       url('../font/fontello.woff?56185592') format('woff'),
       url('../font/fontello.ttf?56185592') format('truetype'),
       url('../font/fontello.svg?56185592#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?56185592#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-left-open:before { content: '\e800'; } /* '' */
.icon-right-open:before { content: '\e801'; } /* '' */
.icon-up-open:before { content: '\e802'; } /* '' */
.icon-down-open:before { content: '\e803'; } /* '' */
.icon-trash-empty:before { content: '\e804'; } /* '' */
.icon-cancel-circled:before { content: '\e805'; } /* '' */
.icon-cancel:before { content: '\e806'; } /* '' */
.icon-download:before { content: '\e807'; } /* '' */
.icon-star:before { content: '\e808'; } /* '' */
.icon-star-empty:before { content: '\e809'; } /* '' */
.icon-star-half:before { content: '\e80a'; } /* '' */
.icon-heart-empty:before { content: '\e80b'; } /* '' */
.icon-heart:before { content: '\e80c'; } /* '' */
.icon-search:before { content: '\e80d'; } /* '' */
.icon-attention:before { content: '\e80e'; } /* '' */
.icon-attention-circled:before { content: '\e80f'; } /* '' */
.icon-phone:before { content: '\e810'; } /* '' */
.icon-chart-bar:before { content: '\e811'; } /* '' */
.icon-group:before { content: '\e812'; } /* '' */
.icon-mail:before { content: '\e813'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-angle-up:before { content: '\f106'; } /* '' */
.icon-angle-down:before { content: '\f107'; } /* '' */
.icon-star-half-alt:before { content: '\f123'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-instagram:before { content: '\f16d'; } /* '' */
.icon-skype:before { content: '\f17e'; } /* '' */
.icon-trash:before { content: '\f1f8'; } /* '' */
.icon-chart-area:before { content: '\f1fe'; } /* '' */
.icon-chart-pie:before { content: '\f200'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-map-o:before { content: '\f278'; } /* '' */
.icon-map:before { content: '\f279'; } /* '' */
.icon-vimeo:before { content: '\f27d'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-o:before { content: '\f2c0'; } /* '' */